import { MonkState, useMonkState } from '@monkvision/common';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Image, ImageType, Sight, VehiclePart, View } from '@monkvision/types';
import { sights } from '@monkvision/sights';
import { InspectionGalleryItem, InspectionGalleryProps, NavigateToCaptureReason } from './types';
import {
  useInspectionGalleryEmptyLabel,
  useInspectionGalleryItems,
  useInspectionGalleryStyles,
  useItemListFillers,
} from './hooks';
import { InspectionGalleryFilter, InspectionGalleryTopBar } from './InspectionGalleryTopBar';
import { InspectionGalleryItemCard } from './InspectionGalleryItemCard';
import { ImageDetailedView } from '../ImageDetailedView';
import {
  TESLA_WHEEL_BACK_LEFT,
  TESLA_WHEEL_BACK_RIGHT,
  TESLA_WHEEL_FRONT_LEFT,
  TESLA_WHEEL_FRONT_RIGHT,
} from '../../../hooks';

function getItemKey(item: InspectionGalleryItem): string {
  if (item.isAddDamage) {
    return 'item-add-damage';
  }
  if (!item.isTaken) {
    return item.sightId;
  }
  return item.image.id;
}

function getSightsSortedByDamageArea(
  inspectionId: string,
  entities: MonkState,
  filteredDamaged = false,
) {
  const viewsWithDamages: Array<View> = [];
  entities.damages
    .filter((damage) => damage.inspectionId === inspectionId)
    .forEach((damage) => {
      entities.views.forEach((view) => view.elementId === damage.id && viewsWithDamages.push(view));
    });

  const sightsWithDamageArea = entities.images
    .filter((image) => image.inspectionId === inspectionId)
    .reduce<Array<{ sight: Sight; damageArea: number }>>((acc, image) => {
      if (!image.sightId) {
        return acc;
      }
      let damageArea = 0;
      image.views.forEach((view) => {
        const viewFound = viewsWithDamages.find((viewWithDamages) => viewWithDamages.id === view);
        if (!viewFound) {
          return;
        }
        const { boundingBox } = viewFound.imageRegion.specification;
        damageArea += boundingBox ? boundingBox.width * boundingBox.height : 0;
      });

      acc.push({ sight: sights[image.sightId], damageArea });
      return acc;
    }, [])
    .sort((a, b) => b.damageArea - a.damageArea);

  const sightsSorted = filteredDamaged
    ? sightsWithDamageArea.filter((s) => s.damageArea > 0)
    : sightsWithDamageArea;

  return sightsSorted.map((sight) => sight.sight);
}

function getFilteredImages(inspectionId: string, filterByPart: VehiclePart, entities: MonkState) {
  const partSelectedId = entities.parts
    .filter((p) => p.inspectionId === inspectionId)
    .find((p) => p.type === filterByPart)?.id;
  const viewsRelated = entities.views
    .filter((v) => v.elementId === partSelectedId)
    .map((v) => v.id);
  const imageIdsFilteredByPart = entities.images
    .filter((i) => i.views.some((v: string) => viewsRelated.includes(v)))
    .map((i) => i.id);
  const imageCloseUpByPart = entities.images
    .filter(
      (image) =>
        image.type === ImageType.CLOSE_UP &&
        image.detailedViewpoint?.centersOn?.includes(filterByPart),
    )
    .map((i) => i.id);
  return [...imageCloseUpByPart, ...imageIdsFilteredByPart];
}

/**
 * This component is used to display a gallery of pictures taken during an inspection. If this component is used
 * mid-capture, set the `captureMode` prop to `true` so that you'll enable features such as compliance errors, retakes
 * etc.
 */
export function InspectionGallery(props: InspectionGalleryProps) {
  const [selectedImage, setSelectedImage] = useState<Image | null>(null);
  const [showDamage, setShowDamage] = useState(false);
  const { state } = useMonkState();
  const items = useInspectionGalleryItems({
    ...props,
    sights: props.filterByPart
      ? getSightsSortedByDamageArea(props.inspectionId, state)
      : props.sights,
  });
  const [currentFilter, setCurrentFilter] = useState<InspectionGalleryFilter | null>(null);
  const isPartItemDamaged = useCallback(
    (item: InspectionGalleryItem) => {
      if (!item.isAddDamage && item.isTaken) {
        if (item.image.type === ImageType.CLOSE_UP) {
          return true;
        }
        const part = state.parts.find(
          (p) => p.inspectionId === props.inspectionId && p.type === props.filterByPart,
        );
        const damage = state.damages.find(
          (d) =>
            d.inspectionId === props.inspectionId &&
            part &&
            d.parts.includes(part.id) &&
            d.relatedImages.includes(item.image.id),
        );
        return !!damage;
      }
      return false;
    },
    [state, props.filterByPart],
  );

  const isPartItemCloseUp = useCallback(
    (item: InspectionGalleryItem) => {
      if (!item.isAddDamage && item.isTaken && item.image.type === ImageType.CLOSE_UP) {
        return true;
      }
      return false;
    },
    [state, props.filterByPart],
  );

  const filteredItems = useMemo(() => {
    const defaultFiteredItems = currentFilter ? items.filter(currentFilter.callback) : items;
    if (props.filterByPart && !props.filterInterior) {
      const imageIdsFilteredByPart: string[] = [];
      getFilteredImages(props.inspectionId, props.filterByPart, state).forEach((i) =>
        imageIdsFilteredByPart.push(i),
      );
      if (props.filterByPart.includes('wheel')) {
        if (props.filterByPart === VehiclePart.WHEEL_FRONT_RIGHT) {
          TESLA_WHEEL_FRONT_RIGHT.forEach((p) => {
            getFilteredImages(props.inspectionId, p, state).forEach((i) =>
              imageIdsFilteredByPart.push(i),
            );
          });
        }
        if (props.filterByPart === VehiclePart.WHEEL_FRONT_LEFT) {
          TESLA_WHEEL_FRONT_LEFT.forEach((p) => {
            getFilteredImages(props.inspectionId, p, state).forEach((i) =>
              imageIdsFilteredByPart.push(i),
            );
          });
        }
        if (props.filterByPart === VehiclePart.WHEEL_BACK_RIGHT) {
          TESLA_WHEEL_BACK_RIGHT.forEach((p) => {
            getFilteredImages(props.inspectionId, p, state).forEach((i) =>
              imageIdsFilteredByPart.push(i),
            );
          });
        }
        if (props.filterByPart === VehiclePart.WHEEL_BACK_LEFT) {
          TESLA_WHEEL_BACK_LEFT.forEach((p) => {
            getFilteredImages(props.inspectionId, p, state).forEach((i) =>
              imageIdsFilteredByPart.push(i),
            );
          });
        }
      }
      return imageIdsFilteredByPart
        ? items
            .filter(
              (item) =>
                !item.isAddDamage &&
                item.isTaken &&
                imageIdsFilteredByPart?.includes(item.image.id),
            )
            .sort((a, b) => {
              const damageA = isPartItemDamaged(a) ? 1 : 0;
              const damageB = isPartItemDamaged(b) ? 1 : 0;
              return damageB - damageA;
            })
            .sort((a, b) => {
              const damageA = isPartItemCloseUp(a) ? 1 : 0;
              const damageB = isPartItemCloseUp(b) ? 1 : 0;
              return damageB - damageA;
            })
        : defaultFiteredItems;
    }
    if (props.filterInterior) {
      const imageFilteredBySightId = state.images
        .filter((image) => image.inspectionId === props.inspectionId)
        .filter((image) => image.sightId?.includes('all'))
        .map((i) => i.id);
      return imageFilteredBySightId
        ? items.filter(
            (item) =>
              !item.isAddDamage && item.isTaken && imageFilteredBySightId?.includes(item.image.id),
          )
        : defaultFiteredItems;
    }
    if (!props.filterInterior) {
      const imageFilteredBySightId = state.images
        .filter((image) => image.inspectionId === props.inspectionId)
        .filter((image) => !image.sightId?.includes('all'))
        .map((i) => i.id);
      return imageFilteredBySightId
        ? items
            .filter(
              (item) =>
                !item.isAddDamage &&
                item.isTaken &&
                imageFilteredBySightId?.includes(item.image.id),
            )
            .sort((a, b) => {
              const damageA = isPartItemCloseUp(a) ? 1 : 0;
              const damageB = isPartItemCloseUp(b) ? 1 : 0;
              return damageB - damageA;
            })
        : defaultFiteredItems;
    }

    return defaultFiteredItems;
  }, [items, currentFilter, props.filterByPart, props.filterInterior]);
  const { containerStyle, itemListStyle, itemStyle, fillerItemStyle, emptyStyle } =
    useInspectionGalleryStyles();
  const fillerCount = useItemListFillers(filteredItems.length);
  const emptyLabel = useInspectionGalleryEmptyLabel({
    captureMode: props.captureMode,
    isFilterActive: !!props.filterByPart,
  });

  const handleItemClick = (item: InspectionGalleryItem) => {
    if (item.isAddDamage && props.captureMode) {
      props.onNavigateToCapture?.({ reason: NavigateToCaptureReason.ADD_DAMAGE });
    } else if (!item.isAddDamage && !item.isTaken && props.captureMode) {
      props.onNavigateToCapture?.({
        reason: NavigateToCaptureReason.CAPTURE_SIGHT,
        sightId: item.sightId,
      });
    } else if (!item.isAddDamage && item.isTaken) {
      setSelectedImage(item.image);
    }
  };

  const handleRetakeImage = (image: Image | null) => {
    if (props.captureMode && image?.sightId) {
      props.onNavigateToCapture?.({
        reason: NavigateToCaptureReason.RETAKE_PICTURE,
        sightId: image.sightId,
      });
    } else if (props.captureMode && image?.type === ImageType.CLOSE_UP) {
      props.onNavigateToCapture?.({
        reason: NavigateToCaptureReason.ADD_DAMAGE,
      });
    }
  };

  const imageDetailedviewCaptureProps = props.captureMode
    ? {
        captureMode: true,
        showCaptureButton: true,
        onNavigateToCapture: () =>
          props.onNavigateToCapture?.({ reason: NavigateToCaptureReason.NONE }),
        onRetake: () => handleRetakeImage(selectedImage),
      }
    : { captureMode: false };

  const moveToPreviousImage = () => {
    const findCurrentImageIndex = () =>
      filteredItems.findIndex(
        (item) => !item.isAddDamage && item.isTaken && item.image.id === selectedImage?.id,
      );
    const itemIndex = findCurrentImageIndex();
    const previousItem = filteredItems.at(!selectedImage ? 0 : itemIndex - 1);

    if (previousItem && !previousItem.isAddDamage && previousItem.isTaken) {
      setSelectedImage(previousItem.image);
    }
  };

  const moveToNextImage = () => {
    const findCurrentImageIndex = () =>
      filteredItems.findIndex(
        (item) => !item.isAddDamage && item.isTaken && item.image.id === selectedImage?.id,
      );
    const itemIndex = findCurrentImageIndex();
    const isLastItem = itemIndex + 1 === filteredItems.length;
    const nextItem = filteredItems.at(isLastItem || !selectedImage ? 0 : itemIndex + 1);

    if (nextItem && !nextItem.isAddDamage && nextItem.isTaken) {
      setSelectedImage(nextItem.image);
    }
  };

  const isItemDamaged = useCallback(
    (item: InspectionGalleryItem) => {
      if (!item.isAddDamage && item.isTaken) {
        if (item.image.type === ImageType.CLOSE_UP) {
          return true;
        }
        if (item.image.sightId) {
          return getSightsSortedByDamageArea(props.inspectionId, state, true)
            .map((s) => s.id)
            .includes(item.image.sightId);
        }
      }
      return false;
    },
    [state],
  );

  const isImageDamaged = useCallback(
    (image: Image) => {
      if (!image.sightId) {
        return false;
      }
      return getSightsSortedByDamageArea(props.inspectionId, state, true)
        .map((s) => s.id)
        .includes(image.sightId);
    },
    [state],
  );

  useEffect(() => {
    const keyActions: { [key: string]: () => void } = {
      s: () => setShowDamage(!showDamage),
      S: () => setShowDamage(!showDamage),
      ArrowLeft: moveToPreviousImage,
      ArrowRight: moveToNextImage,
      q: () => setSelectedImage(null),
      Escape: () => setSelectedImage(null),
    };

    const handleKeyDown = (event: KeyboardEvent) => {
      const action = keyActions[event.key];
      if (action) {
        action();
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [selectedImage, filteredItems, showDamage]);

  useEffect(() => {
    setSelectedImage(null);
  }, [props.filterByPart]);

  if (selectedImage) {
    return (
      <ImageDetailedView
        lang={props.lang}
        image={selectedImage}
        showGalleryButton={isImageDamaged(selectedImage) && !props.filterInterior}
        reportMode={!!props.reportMode}
        showDamage={showDamage}
        onShowDamage={() => setShowDamage(!showDamage)}
        onClose={() => setSelectedImage(null)}
        onPrev={moveToPreviousImage}
        onNext={moveToNextImage}
        onNavigateToGallery={() => setSelectedImage(null)}
        {...imageDetailedviewCaptureProps}
      />
    );
  }

  return (
    <div style={containerStyle}>
      {!props.reportMode && (
        <InspectionGalleryTopBar
          items={items}
          currentFilter={currentFilter}
          onUpdateFilter={(filter) => setCurrentFilter((c) => (c === filter ? null : filter))}
          showBackButton={props.showBackButton}
          onBack={props.onBack}
          captureMode={props.captureMode}
          onValidate={props.onValidate}
          allowSkipRetake={props.captureMode && !!props.allowSkipRetake}
          validateButtonLabel={props.validateButtonLabel}
        />
      )}
      <div style={itemListStyle}>
        {filteredItems.length === 0 && <div style={emptyStyle}>{emptyLabel}</div>}
        {filteredItems.map((item) => (
          <div style={itemStyle} key={getItemKey(item)}>
            <InspectionGalleryItemCard
              item={item}
              isDamaged={props.filterByPart ? isPartItemDamaged(item) : isItemDamaged(item)}
              captureMode={props.captureMode}
              onClick={() => handleItemClick(item)}
            />
          </div>
        ))}
        {filteredItems.length > 0 &&
          Array.from(Array(fillerCount).keys()).map((key) => (
            <div style={fillerItemStyle} key={key} />
          ))}
      </div>
    </div>
  );
}
