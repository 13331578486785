import { ActionIcon, Loader, LoadingOverlay, Modal, Select, Table } from '@mantine/core';
import {
  IconArrowDown,
  IconArrowsDiagonal,
  IconArrowUp,
  IconCaretLeftFilled,
  IconCaretRightFilled,
  IconChevronDown,
  IconPencil,
} from '@tabler/icons-react';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { LoadingState, useMonkAppState } from '@monkvision/common';
import { clsx } from 'clsx';
import { useDisclosure } from '@mantine/hooks';
import { useState } from 'react';
import { DebouncedState } from 'use-debounce';
import styles from './InspectionListView.module.css';
import {
  useExportToCsv,
  UseTeslaInspectionListFiltersResult,
  UseTeslaInspectionListResult,
} from '../../hooks';
import { STATUS_TRANSLATION_KEYS } from '../DashboardDrawer';
import { InspectionDetails, isInspectionReviewAvailable } from '../InspectionDetails';
import { InspectionReview } from '../InspectionReview';
import { InspectionListFilters, InspectionListFiltersProps } from '../InspectionListFilters';
import { TeslaInspection, TeslaInspectionStatus } from '../../hooks/useTeslaInspectionList/types';
import {
  SortByProperty,
  SortDirection,
  TeslaInspectionListFilters,
} from '../../hooks/useTeslaInspectionListFilters/types';
import { InspectionReviewHeader } from '../InspectionReview/Header/Header';
import { AppRegion, useAppRegion } from '../../contexts';

export interface InspectionListProps
  extends Pick<UseTeslaInspectionListFiltersResult, 'toggleSortBy'>,
    Pick<
      InspectionListFiltersProps,
      'filterByDate' | 'filterBySearchInput' | 'filterByStatuses' | 'filters'
    >,
    Pick<
      UseTeslaInspectionListResult,
      'inspections' | 'pagination' | 'setRowsPerPage' | 'goToNextPage' | 'goToPreviousPage'
    > {
  inspections: TeslaInspection[];
  showStatusColumn: boolean;
  filters: TeslaInspectionListFilters;
  itemsLoading: LoadingState;
  totalLoading: LoadingState;
  currency: string;
  status: TeslaInspectionStatus | null;
  onStatusChange: DebouncedState<(inspectionId: string, value: TeslaInspectionStatus) => void>;
}

function formatLastUpdated(value?: Date): string | undefined {
  return value ? dayjs(value).format('YYYY-MM-DD HH:mm:ss') : undefined;
}

function formatLeaseMaturityDate(value?: Date): string | undefined {
  return value ? dayjs(value).format('YYYY-MM-DD') : undefined;
}

function NoData() {
  const { t } = useTranslation();
  return <span className={styles['noData']}>{t('inspectionList.table.noData')}</span>;
}

export function InspectionListView({
  showStatusColumn,
  filters,
  itemsLoading,
  totalLoading,
  toggleSortBy,
  inspections,
  pagination,
  setRowsPerPage,
  goToNextPage,
  goToPreviousPage,
  currency,
  filterByDate,
  filterBySearchInput,
  filterByStatuses,
  status,
  onStatusChange,
}: InspectionListProps) {
  const [detailsOpened, { open: openDetails, close: closeDetails }] = useDisclosure(false);
  const [reviewOpened, { open: openReview, close: closeReview }] = useDisclosure(false);
  const [openedInspection, setOpenedInspection] = useState<TeslaInspection | null>(null);
  const { exportToCsv, loading: exportToCsvLoading } = useExportToCsv({ inspections, currency });
  const { t } = useTranslation();
  const { region } = useAppRegion();
  const { config, authToken } = useMonkAppState();
  const apiConfig = {
    authToken: authToken ?? '',
    apiDomain: config.apiDomain,
    thumbnailDomain: config.thumbnailDomain,
  };

  const paginationDisabled =
    itemsLoading.isLoading ||
    !!itemsLoading.error ||
    totalLoading.isLoading ||
    !!totalLoading.error ||
    inspections.length === 0;
  const handleSortClick = (property: SortByProperty) => {
    if (!paginationDisabled) {
      toggleSortBy(property);
    }
  };

  const selectRowsPerPage = (value: string | null) => {
    if (value) {
      setRowsPerPage(Number(value));
    }
  };

  const SortIcon = filters.sortDirection === SortDirection.ASCENDING ? IconArrowUp : IconArrowDown;
  const sort = <SortIcon size={14} color='black' className={styles['sortIcon']} />;
  const maxColSpan = showStatusColumn ? 7 : 6;

  const openInspectionDetails = (inspection: TeslaInspection) => {
    setOpenedInspection(inspection);
    openDetails();
  };

  const openInspectionReview = (inspection: TeslaInspection) => {
    closeDetails();
    setOpenedInspection(inspection);
    openReview();
  };

  return (
    <div className={styles['container']}>
      <Modal opened={detailsOpened} onClose={closeDetails}>
        {openedInspection && (
          <InspectionDetails
            inspection={openedInspection}
            onOpenReview={openInspectionReview}
            onStatusChange={onStatusChange}
          />
        )}
      </Modal>
      <Modal
        opened={reviewOpened}
        onClose={closeReview}
        size={openedInspection?.inspectionId ? '100dvw' : undefined}
        title={<InspectionReviewHeader vin={openedInspection?.vin} currency={currency} />}
        classNames={{ header: styles['modalHeader'] }}
        closeOnEscape={false}
      >
        {openedInspection?.inspectionId ? (
          <div className={styles['inspectionReviewContainer']}>
            <InspectionReview
              inspectionId={openedInspection?.inspectionId}
              apiConfig={apiConfig}
              onStatusChange={onStatusChange}
            />
          </div>
        ) : (
          <div className={styles['tempMessage']}>
            Pass an inspection ID in the URL parameters to be able to edit an inspection.
          </div>
        )}
      </Modal>
      <InspectionListFilters
        title={t(STATUS_TRANSLATION_KEYS[status ?? 'all'])}
        filters={filters}
        filterByDate={filterByDate}
        filterBySearchInput={filterBySearchInput}
        filterByStatuses={filterByStatuses}
        showStatusFilter={status === null}
        isListLoading={itemsLoading.isLoading || totalLoading.isLoading}
        onExportToCsv={exportToCsv}
        exportToCsvLoading={exportToCsvLoading}
      />
      <Table striped highlightOnHover withBorder>
        <thead>
          <tr>
            <th className={styles['actionIconTh']}></th>
            <th>
              <div className={styles['thContent']}>{t('inspectionList.table.vin')}</div>
            </th>
            <th>
              <div className={styles['thContent']}>{t('inspectionList.table.model')}</div>
            </th>
            {showStatusColumn && (
              <th>
                <div className={styles['thContent']}>{t('inspectionList.table.status')}</div>
              </th>
            )}
            <th>
              <div className={styles['thContent']}>{t('inspectionList.table.country')}</div>
            </th>
            <th
              onClick={() => handleSortClick(SortByProperty.LAST_UPDATED)}
              className={clsx({ [styles['clickableTh']]: !paginationDisabled })}
            >
              <div className={styles['thContent']}>
                {t('inspectionList.table.lastUpdated')}
                {filters.sortBy === SortByProperty.LAST_UPDATED && sort}
              </div>
            </th>
            {region !== AppRegion.US && (
              <th
                onClick={() => handleSortClick(SortByProperty.LEASE_MATURITY_DATE)}
                className={clsx({ [styles['clickableTh']]: !paginationDisabled })}
              >
                <div className={styles['thContent']}>
                  {t('inspectionList.table.leaseMaturityDate')}
                  {filters.sortBy === SortByProperty.LEASE_MATURITY_DATE && sort}
                </div>
              </th>
            )}
          </tr>
        </thead>
        <tbody className={styles['tbody']}>
          {(itemsLoading.isLoading || totalLoading.isLoading) && (
            <tr className={styles['loaderTr']}>
              <td>
                <LoadingOverlay
                  visible={true}
                  overlayBlur={2}
                  color='dark'
                  loader={<Loader color='black' />}
                />
              </td>
            </tr>
          )}
          {(itemsLoading.isLoading || totalLoading.isLoading) && inspections.length === 0 && (
            <tr>
              <td colSpan={maxColSpan}>
                <div className={styles['emptyFiller']}></div>
              </td>
            </tr>
          )}
          {(itemsLoading.error || totalLoading.error) && (
            <tr>
              <td colSpan={maxColSpan} className={clsx(styles['messageTd'], styles['error'])}>
                {t('inspectionList.table.error')}
              </td>
            </tr>
          )}
          {!itemsLoading.isLoading && !totalLoading.error && inspections.length === 0 && (
            <tr>
              <td colSpan={maxColSpan} className={styles['messageTd']}>
                <div>{t('inspectionList.table.empty')}</div>
              </td>
            </tr>
          )}
          {!itemsLoading.isLoading &&
            !totalLoading.error &&
            inspections.map((inspection) => (
              <tr key={inspection.inspectionId}>
                <td className={styles['actionIconTd']}>
                  {isInspectionReviewAvailable(inspection) && (
                    <ActionIcon
                      variant='subtle'
                      color='dark'
                      onClick={() => openInspectionReview(inspection)}
                    >
                      <IconPencil size={14} />
                    </ActionIcon>
                  )}
                  <ActionIcon
                    variant='subtle'
                    color='dark'
                    onClick={() => openInspectionDetails(inspection)}
                  >
                    <IconArrowsDiagonal size={14} />
                  </ActionIcon>
                </td>
                <td>{inspection.vin ?? <NoData />}</td>
                <td>{inspection.model ?? <NoData />}</td>
                {showStatusColumn && (
                  <td>
                    {inspection.status ? t(STATUS_TRANSLATION_KEYS[inspection.status]) : <NoData />}
                  </td>
                )}
                <td>{inspection.country ?? <NoData />}</td>
                <td>{formatLastUpdated(inspection.lastUpdated) ?? <NoData />}</td>

                {region !== AppRegion.US && (
                  <td>{formatLeaseMaturityDate(inspection.leaseMaturityDate) ?? <NoData />}</td>
                )}
              </tr>
            ))}
        </tbody>
      </Table>
      <div
        className={clsx(styles['paginationContainer'], {
          [styles['loading']]: itemsLoading.isLoading || totalLoading.isLoading,
        })}
      >
        <div>{t('inspectionList.table.rowsPerPage')}</div>
        <Select
          value={pagination?.rowsPerPage.toString() ?? '25'}
          data={['25', '50', '75', '100']}
          variant='unstyled'
          color='dark'
          onChange={selectRowsPerPage}
          disabled={paginationDisabled}
          rightSection={<IconChevronDown size='1rem' />}
          classNames={{
            root: styles['rowsPerPageSelect'],
            rightSection: styles['rowsPerPageSelectRightSection'],
            item: styles['rowsPerPageSelectItem'],
          }}
        />
        <div>
          {pagination?.start ?? 0}-{pagination?.end ?? 0}
        </div>
        <div className={styles['of']}>{t('inspectionList.table.of')}</div>
        <div>{pagination?.totalItems ?? 0}</div>
        <ActionIcon
          variant='transparent'
          color='dark'
          disabled={!pagination || pagination.start < 2 || paginationDisabled}
          onClick={goToPreviousPage}
          className={styles['previousPage']}
        >
          <IconCaretLeftFilled />
        </ActionIcon>
        <ActionIcon
          variant='transparent'
          color='dark'
          disabled={!pagination || pagination.end >= pagination.totalItems || paginationDisabled}
          onClick={goToNextPage}
        >
          <IconCaretRightFilled />
        </ActionIcon>
      </div>
    </div>
  );
}
