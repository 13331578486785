import { Fragment } from 'react';
import { Image, Text, View } from '@react-pdf/renderer';
import { useTranslation } from 'react-i18next';
import { damageTypeLabels, useObjectTranslation, vehiclePartLabels } from '@monkvision/common';
import { DAMAGE_COLORS, PdfVehicleSideData } from '../../../hooks/useTeslaPdfGenerator/types';
import { SectionTitle } from '../SectionTitle';
import { styles } from './DamageReport.styles';
import { formatPrice, PdfVehicleSide } from '../../../utils';

export interface DamageReportProps {
  damagedSides: PdfVehicleSideData[];
  currency: string;
  lang: string;
}

function getSideTitleKey(side: PdfVehicleSide): string {
  switch (side) {
    case PdfVehicleSide.LEFT:
      return 'pdf.damageReport.sides.left';
    case PdfVehicleSide.FRONT:
      return 'pdf.damageReport.sides.front';
    case PdfVehicleSide.RIGHT:
      return 'pdf.damageReport.sides.right';
    case PdfVehicleSide.REAR:
      return 'pdf.damageReport.sides.rear';
    default:
      return '' as never;
  }
}

export function DamageReport({ damagedSides, currency, lang }: DamageReportProps) {
  const { t } = useTranslation('translation', { lng: lang });
  const { tObj } = useObjectTranslation('translation', { lng: lang });

  return (
    <View style={styles.container}>
      <SectionTitle paddingTop title={t('pdf.damageReport.title')} />
      {damagedSides.map(({ side, referencePhotoUrl, damagedParts }, sideIndex) => (
        <View key={side} style={styles.container}>
          <View wrap={false} style={styles.container}>
            <Text
              style={{ ...styles.sideTitle, ...(sideIndex > 0 ? styles.sideTitleTopPadding : {}) }}
            >
              {t(getSideTitleKey(side))}
            </Text>
            <View style={styles.referencePhotoContainer}>
              <Image style={styles.referencePhoto} src={referencePhotoUrl} />
            </View>
          </View>
          <View style={{ ...styles.row, ...styles.headerRow }}>
            <View style={{ ...styles.cell, ...styles.headerCell }}>
              <Text>{t('pdf.damageReport.vehiclePart')}</Text>
            </View>
            <View style={{ ...styles.cell, ...styles.headerCell }}>
              <Text>{t('pdf.damageReport.damageType')}</Text>
            </View>
            <View style={{ ...styles.cell, ...styles.headerCell }}>
              <Text>{t('pdf.damageReport.price')}</Text>
            </View>
          </View>
          {damagedParts.map((part) => (
            <View key={part.id} wrap={false} style={styles.damagesRowsContainer}>
              <View style={{ ...styles.row, ...styles.damagesRow }}>
                <View style={{ ...styles.cell, ...styles.damagesCell }}>
                  <View style={styles.vehiclePartContainer}>
                    <View style={styles.vehiclePartId}>
                      <Text>{part.id}</Text>
                    </View>
                    <Text>
                      {vehiclePartLabels[part.name]
                        ? tObj(vehiclePartLabels[part.name])
                        : 'unknown'}
                    </Text>
                  </View>
                </View>
                <View style={{ ...styles.cell, ...styles.damagesCell }}>
                  {part.damages.map((type, index) => (
                    <Fragment key={`${part.id}-${type}`}>
                      {index > 0 && <Text style={styles.damageTypeSeparator}>/</Text>}
                      <View
                        style={{
                          ...styles.damageTypeColorIndicator,
                          backgroundColor: DAMAGE_COLORS[type],
                        }}
                      />
                      <Text>
                        {damageTypeLabels[type] ? tObj(damageTypeLabels[type]) : 'unknown'}
                      </Text>
                    </Fragment>
                  ))}
                </View>
                <View style={{ ...styles.cell, ...styles.damagesCell }}>
                  <Text>{formatPrice(part.repairCost, currency, lang)}</Text>
                </View>
              </View>
              <View style={{ ...styles.row, ...styles.croppedPhotosRow }}>
                <View style={styles.croppedPhotoLabel}>
                  <Text>{t('pdf.damageReport.croppedPhotos')}</Text>
                </View>
                {part.croppedPhotoUrls.map((url) => (
                  <Image key={url} src={url} style={styles.croppedPhoto} />
                ))}
                {part.closeUpPhotoUrls.map((url) => (
                  <Image key={url} src={url} style={styles.croppedPhoto} />
                ))}
              </View>
            </View>
          ))}
        </View>
      ))}
    </View>
  );
}
