import {
  IconCircleFilled,
  IconLetterQ,
  IconLetterS,
  IconArrowBigLeft,
  IconArrowBigRight,
} from '@tabler/icons-react';
import { useTranslation } from 'react-i18next';
import { PRICING_LEGENDS } from '../../../hooks';
import styles from './Header.module.css';

function PricingLegend({
  currency,
  color,
  comparison,
  value,
}: {
  currency: string;
  color: string;
  comparison: string;
  value: number;
}) {
  return (
    <div className={styles['legend']}>
      <IconCircleFilled style={{ color }} />
      <div>
        {comparison}
        {value !== 0 && (
          <>
            {currency === '$' ? currency : ''}
            {value}
            {currency !== '$' ? currency : ''}
          </>
        )}
      </div>
    </div>
  );
}

function ShortcutLegend({
  IconComponent,
  text,
}: {
  IconComponent: React.ElementType;
  text: string;
}) {
  return (
    <div className={styles['legend']}>
      <IconComponent className={styles['icon']} stroke={3} color={'white'} />
      {text}
    </div>
  );
}

export function InspectionReviewHeader({ vin, currency }: { vin?: string; currency: string }) {
  const { t } = useTranslation();
  return (
    <div className={styles['container']}>
      <div className={styles['legend']}>
        <span className={styles['vin']}>VIN: {vin}</span>
      </div>
      <PricingLegend
        currency={currency}
        color={PRICING_LEGENDS.tire.color}
        comparison={t('inspectionReview.header.needPricing')}
        value={0}
      />
      <PricingLegend
        currency={currency}
        color={PRICING_LEGENDS.low.color}
        comparison='< '
        value={PRICING_LEGENDS.low.max}
      />
      <PricingLegend
        currency={currency}
        color={PRICING_LEGENDS.mid.color}
        comparison='< '
        value={PRICING_LEGENDS.mid.max}
      />
      <PricingLegend
        currency={currency}
        color={PRICING_LEGENDS.high.color}
        comparison='> '
        value={PRICING_LEGENDS.high.min}
      />
      <ShortcutLegend
        IconComponent={IconLetterS}
        text={t('inspectionReview.header.showHideDamages')}
      />
      <ShortcutLegend
        IconComponent={IconLetterQ}
        text={t('inspectionReview.header.closeCurrentImage')}
      />
      <div className={styles['legend']}>
        <IconArrowBigLeft className={styles['icon']} stroke={3} color={'white'} />
        <IconArrowBigRight className={styles['icon']} stroke={3} color={'white'} />
        {t('inspectionReview.header.changeImage')}
      </div>
    </div>
  );
}
