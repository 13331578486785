import { Button, Checkbox, Popover } from '@mantine/core';
import { Calendar, DatePickerInput, DatesRangeValue } from '@mantine/dates';
import { IconCalendar } from '@tabler/icons-react';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './DateFilter.module.css';
import { UseTeslaInspectionListFiltersResult } from '../../hooks';
import { TeslaInspectionListFilters } from '../../hooks/useTeslaInspectionListFilters/types';
import { AppRegion, useAppRegion } from '../../contexts';

export interface DateFilterProps extends Pick<UseTeslaInspectionListFiltersResult, 'filterByDate'> {
  filters: TeslaInspectionListFilters;
  isListLoading: boolean;
}

export function DateFilter({ filters, filterByDate, isListLoading }: DateFilterProps) {
  const filtersMin = useMemo(
    () => filters.lastUpdated?.min ?? filters.leaseMaturityDate?.min ?? null,
    [filters],
  );
  const filtersMax = useMemo(
    () => filters.lastUpdated?.max ?? filters.leaseMaturityDate?.max ?? null,
    [filters],
  );
  const [isDatePickerOpened, setDatePickerOpened] = useState(false);
  const [lastUpdated, setLastUpdated] = useState(!!filters.lastUpdated);
  const [leaseMaturityDate, setLeaseMaturityDate] = useState(!!filters.leaseMaturityDate);
  const [min, setMin] = useState<Date | null>(null);
  const [max, setMax] = useState<Date | null>(null);
  const [selected, setSelected] = useState<Date | null>(null);
  const [hovered, setHovered] = useState<Date | null>(null);
  const { t, i18n } = useTranslation();
  const { region } = useAppRegion();

  const isApplyDisabled = useMemo(
    () =>
      !min ||
      !max ||
      (!lastUpdated && !leaseMaturityDate) ||
      (lastUpdated === !!filters.lastUpdated &&
        leaseMaturityDate === !!filters.leaseMaturityDate &&
        filtersMin?.getTime() === min.getTime() &&
        filtersMax?.getTime() === max.getTime()),
    [min, max, lastUpdated, leaseMaturityDate, filters, filtersMin, filtersMax],
  );

  const handleClickDay = (date: Date) => {
    if (min || max) {
      setMin(null);
      setMax(null);
    }
    if (!selected) {
      setSelected(date);
    } else if (date < selected) {
      setMin(date);
      setMax(selected);
      setSelected(null);
    } else {
      setMin(selected);
      setMax(date);
      setSelected(null);
    }
  };

  const isInRange = (date: Date) => {
    if (min && max) {
      return min <= date && date <= max;
    }
    return (
      !!selected &&
      !!hovered &&
      ((hovered <= date && date <= selected) || (selected <= date && date <= hovered))
    );
  };

  const isFirstInRange = (date: Date) => {
    if (min) {
      return min.getTime() === date.getTime();
    }
    return !!selected && !!hovered && date.getTime() === selected.getTime() && hovered > selected;
  };

  const isLastInRange = (date: Date) => {
    if (max) {
      return max.getTime() === date.getTime();
    }
    return !!selected && !!hovered && date.getTime() === selected.getTime() && hovered < selected;
  };

  const isSelected = (date: Date) => {
    return [min?.getTime(), max?.getTime(), selected?.getTime()].includes(date.getTime());
  };

  const handlePopoverClose = () => {
    setDatePickerOpened(false);
    setLastUpdated(!!filters.lastUpdated);
    setLeaseMaturityDate(!!filters.leaseMaturityDate);
    setMin(filtersMin);
    setMax(filtersMax);
    setSelected(null);
  };

  const handleApply = () => {
    filterByDate(leaseMaturityDate, lastUpdated, min, max);
    setDatePickerOpened(false);
  };

  const handleInputChange = ([changeMin, changeMax]: DatesRangeValue) => {
    if (!changeMin || !changeMax) {
      filterByDate(false, false, null, null);
      setLastUpdated(false);
      setLeaseMaturityDate(false);
      setMin(null);
      setMax(null);
      setSelected(null);
    }
  };

  return (
    <Popover
      opened={isDatePickerOpened}
      onChange={setDatePickerOpened}
      onClose={handlePopoverClose}
    >
      <Popover.Target>
        <DatePickerInput
          locale={i18n.language}
          icon={<IconCalendar size={14} />}
          type='range'
          clearable
          allowSingleDateInRange
          placeholder={t('inspectionList.filterByDate')}
          classNames={{
            wrapper: styles['datePickerWrapper'],
            input: styles['datePickerInput'],
          }}
          value={[filtersMin, filtersMax]}
          disabled={isListLoading}
          popoverProps={{ opened: false }}
          onClick={() => setDatePickerOpened(true)}
          onChange={handleInputChange}
        />
      </Popover.Target>
      <Popover.Dropdown>
        <Checkbox
          checked={lastUpdated}
          onChange={(e) => setLastUpdated(e.target.checked)}
          label={t('inspectionList.table.lastUpdated')}
          size='md'
          color='dark'
          className={styles['checkbox']}
        />
        {region !== AppRegion.US && (
          <Checkbox
            checked={leaseMaturityDate}
            onChange={(e) => setLeaseMaturityDate(e.target.checked)}
            label={t('inspectionList.table.leaseMaturityDate')}
            size='md'
            color='dark'
            className={styles['checkbox']}
          />
        )}
        <Calendar
          color='dark'
          getDayProps={(date) => ({
            onMouseEnter: () => setHovered(date),
            onMouseLeave: () => setHovered(null),
            onClick: () => handleClickDay(date),
            selected: isSelected(date),
            inRange: isInRange(date),
            firstInRange: isFirstInRange(date),
            lastInRange: isLastInRange(date),
          })}
        />
        <div className={styles['buttonsContainer']}>
          <Button variant='outline' color='dark' onClick={handlePopoverClose}>
            {t('inspectionList.cancel')}
          </Button>
          <Button color='dark' disabled={isApplyDisabled} onClick={handleApply}>
            {t('inspectionList.apply')}
          </Button>
        </div>
      </Popover.Dropdown>
    </Popover>
  );
}
