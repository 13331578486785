import { damageTypeLabels, vehiclePartLabels, useObjectTranslation } from '@monkvision/common';
import { Button } from '@monkvision/common-ui-web';
import { DamageType } from '@monkvision/types';
import { useTranslation } from 'react-i18next';
import { PartDetails } from '../../../../hooks';
import { DamagesSwitchButton } from '../DamageSwitchButton';
import { DoneButton } from '../DoneButton';
import { styles } from './ExteriorManipulatorModal.styles';

const firstColumnItems = [
  DamageType.BROKEN_GLASS,
  DamageType.DENT,
  DamageType.SCRATCH,
  DamageType.PAINT_DAMAGE,
];
const secondColumnItems = [
  DamageType.BODY_CRACK,
  DamageType.MISSING_HUBCAP,
  DamageType.MISSING_PIECE,
  DamageType.RUSTINESS,
  DamageType.MISSHAPE,
];

export interface ExteriorManipulatorModalProps {
  selectedPartDetails: PartDetails | undefined;
  currency: string;
  onConfirm?: (selectedPartDetails: PartDetails) => void;
  onCancel?: () => void;
  onDamageToggle?: () => void;
  onDamageType?: (selectedDamageType: DamageType) => void;
  onPriceChange?: (selectedPrice: number | undefined) => void;
}

export function ExteriorManipulatorModal({
  selectedPartDetails,
  currency,
  onConfirm = () => {},
  onCancel = () => {},
  onDamageToggle = () => {},
  onDamageType = () => {},
  onPriceChange = () => {},
}: ExteriorManipulatorModalProps) {
  const { t } = useTranslation();
  const { tObj } = useObjectTranslation();

  if (!selectedPartDetails) {
    return null;
  }

  return (
    <div style={styles['container']}>
      <div style={styles['content']}>
        <div style={styles['title']}>
          {vehiclePartLabels[selectedPartDetails.part]
            ? tObj(vehiclePartLabels[selectedPartDetails.part])
            : 'unknown'}
        </div>
        <DamagesSwitchButton hasDamage={selectedPartDetails.isDamaged} onSwitch={onDamageToggle} />
        {selectedPartDetails.isDamaged && (
          <>
            <div style={styles['section']}>{t('inspectionReview.damageManipulator.damages')}</div>
            <div style={styles['isDamageContainer']}>
              <div style={styles['damagesColumnContainer']}>
                {firstColumnItems.map((damage) => (
                  <Button
                    key={damage}
                    primaryColor={
                      selectedPartDetails.damagesType.includes(damage) ? 'primary' : 'secondary'
                    }
                    secondaryColor={'text-white'}
                    onClick={() => onDamageType(damage)}
                  >
                    {damageTypeLabels[damage] ? tObj(damageTypeLabels[damage]) : 'unknown'}
                  </Button>
                ))}
              </div>
              <div style={styles['damagesColumnContainer']}>
                {secondColumnItems.map((damage) => (
                  <Button
                    key={damage}
                    primaryColor={
                      selectedPartDetails.damagesType.includes(damage) ? 'primary' : 'secondary'
                    }
                    secondaryColor={'text-white'}
                    onClick={() => onDamageType(damage)}
                  >
                    {damageTypeLabels[damage] ? tObj(damageTypeLabels[damage]) : 'unknown'}
                  </Button>
                ))}
              </div>
            </div>
            <div style={styles['inputSectionContainer']}>
              <div style={styles['section']}>{t('inspectionReview.damageManipulator.price')}</div>
              <div style={styles['inputSection']}>
                {currency === '$' && (
                  <div style={{ alignSelf: 'center', paddingLeft: '20px', paddingRight: '5px' }}>
                    {currency}
                  </div>
                )}
                <input
                  type='text'
                  style={{
                    ...styles['price'],
                    justifyItems: currency === '$' ? 'start' : 'end',
                  }}
                  maxLength={4}
                  value={selectedPartDetails.pricing ?? ''}
                  onChange={(e) => {
                    const { value } = e.target;
                    if (value === '' || /^\d*$/.test(value)) {
                      onPriceChange(value === '' ? undefined : Number(value));
                    }
                  }}
                />
                {currency !== '$' && (
                  <div style={{ alignSelf: 'center', paddingLeft: '5px', paddingRight: '20px' }}>
                    {currency}
                  </div>
                )}
              </div>
            </div>
          </>
        )}
        <div style={styles['footerContainer']}>
          <button style={{ ...styles['button'], ...styles['cancel'] }} onClick={onCancel}>
            {t('inspectionReview.damageManipulator.cancel').toUpperCase()}
          </button>
          <DoneButton
            disabled={selectedPartDetails.isDamaged && !selectedPartDetails.damagesType.length}
            onConfirm={() => onConfirm(selectedPartDetails)}
          >
            {t('inspectionReview.damageManipulator.doneBtn').toUpperCase()}
          </DoneButton>
        </div>
      </div>
    </div>
  );
}
