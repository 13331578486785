import { useEffect, useMemo, useState } from 'react';
import {
  LoadingState,
  MonkState,
  useAsyncEffect,
  useLoadingState,
  useMonkAppState,
  useMonkState,
  useObjectMemo,
} from '@monkvision/common';
import { useMonitoring } from '@monkvision/monitoring';
import { useMonkApi, GetAllInspectionsOptions } from '@monkvision/network';
import { DebouncedState, useDebouncedCallback } from 'use-debounce';
import { SortOrder } from '@monkvision/types';
import {
  Pagination,
  TeslaInspection,
  TeslaModel,
  TeslaCountry,
  TeslaDeductionDetails,
  TeslaInspectionStatus,
} from './types';
import {
  SortByProperty,
  SortDirection,
  TeslaInspectionListParams,
} from '../useTeslaInspectionListFilters/types';
import { TeslaAdditionalData } from '../useCreateTeslaInspectionLink/types';
import { generateDeductionDetails } from '../../utils';

export const INITIAL_ROWS_PER_PAGE = 25;

export interface UseTeslaInspectionListResult {
  itemsLoading: LoadingState;
  totalLoading: LoadingState;
  inspections: TeslaInspection[];
  pagination: Pagination | null;
  setRowsPerPage: (value: number) => void;
  goToNextPage: () => void;
  goToPreviousPage: () => void;
  handleStatusChange: DebouncedState<(inspectionId: string, value: TeslaInspectionStatus) => void>;
}

export const MAPPING_SORTABLE_FIELD: Record<SortByProperty, string> = {
  [SortByProperty.LEASE_MATURITY_DATE]: 'lease_end_date',
  [SortByProperty.LAST_UPDATED]: 'last_update_date',
};

export const MAPPING_SORT_DIRECTION: Record<SortDirection, string> = {
  [SortDirection.ASCENDING]: 'asc',
  [SortDirection.DESCENDING]: 'desc',
};

function mapTeslaInspections(inspectionIds: string[], entities: MonkState): TeslaInspection[] {
  const inspectionMap = new Map(
    entities.inspections.map((inspection) => [inspection.id, inspection]),
  );
  const inspections = inspectionIds
    .map((id) => inspectionMap.get(id))
    .filter(
      (inspection): inspection is (typeof entities.inspections)[number] => inspection !== undefined,
    );
  return inspections.map((inspection) => {
    const additionalData = inspection.additionalData as TeslaAdditionalData;
    const vehicle = entities.vehicles.find((v) => v.id === inspection.vehicle);
    const pricings = entities.pricings.filter((p) => inspection.pricings?.includes(p.id));
    const interiorCost = additionalData.other_damages
      ? additionalData.other_damages.reduce((prev, damage) => prev + damage.repair_cost, 0)
      : 0;
    const interiorDamages = additionalData.other_damages
      ? additionalData.other_damages.map((damage) => ({
          area: damage.area,
          damages: damage.damage_type,
          pricing: damage.repair_cost,
        }))
      : [];
    return {
      status: additionalData.customer_status,
      vin: vehicle?.vin,
      model: vehicle?.model as TeslaModel,
      lastUpdated: additionalData.last_update_date
        ? new Date(additionalData.last_update_date as string)
        : undefined,
      leaseMaturityDate: additionalData.lease_end_date
        ? new Date(additionalData.lease_end_date as string)
        : undefined,
      country: additionalData.country as TeslaCountry,
      inspectionId: inspection.id,
      licencePlate: vehicle?.plate,
      odometer: vehicle?.mileageValue,
      mileageUnit: vehicle?.mileageUnit,
      totalPricing:
        pricings.reduce((total, pricing) => total + (pricing.pricing ?? 0), 0) + interiorCost,
      deductionDetails: generateDeductionDetails(inspection, entities),
      interiorDamages,
      pdfUrl: inspection.pdfUrl,
      inspectionStart: additionalData.inspection_start
        ? new Date(additionalData.inspection_start)
        : undefined,
      inspectionEnd: additionalData.inspection_end
        ? new Date(additionalData.inspection_end)
        : undefined,
      originalDeductionDetails: additionalData.tesla_original_deductions as TeslaDeductionDetails[],
    };
  });
}

function addOneDay(date: Date): Date {
  const adjustedDate = new Date(date);
  adjustedDate.setDate(adjustedDate.getDate() + 1);
  adjustedDate.setTime(adjustedDate.getTime() - 1);
  return adjustedDate;
}

export function useTeslaInspectionList({
  sortDirection,
  sortBy,
  statuses,
  leaseMaturityDate,
  lastUpdated,
  searchInput,
  triggerEffect,
  ownershipFilter,
  setTriggerEffect,
}: TeslaInspectionListParams): UseTeslaInspectionListResult {
  const [limit, setLimit] = useState(INITIAL_ROWS_PER_PAGE);
  const [before, setBefore] = useState<string | null>(null);
  const [previous, setPrevious] = useState<string | null>(null);
  const [next, setNext] = useState<string | null>(null);
  const [totalItems, setTotalItems] = useState<number | null>(null);
  const [page, setPage] = useState(0);
  const [previousPage, setPreviousPage] = useState(0);
  const [inspectionIds, setInspectionIds] = useState<string[]>([]);
  const [cursor, setCursor] = useState<{ before?: string; after?: string } | null>(null);
  const itemsLoading = useLoadingState();
  const totalLoading = useLoadingState();
  const { handleError } = useMonitoring();
  const { config, authToken } = useMonkAppState();
  const apiConfig = {
    authToken: authToken ?? '',
    apiDomain: config.apiDomain,
    thumbnailDomain: config.thumbnailDomain,
  };
  const { getAllInspections, getAllInspectionsCount, updateAdditionalData } = useMonkApi(apiConfig);
  const { state } = useMonkState();

  const inspections = useMemo(
    () => mapTeslaInspections(inspectionIds, state),
    [inspectionIds, state],
  );

  const pagination = useMemo(
    () => ({
      rowsPerPage: limit,
      start: page * limit + 1,
      end: Math.min((page + 1) * limit, totalItems ?? Infinity),
      totalItems: totalItems ?? 0,
    }),
    [limit, page, totalItems],
  );

  useEffect(() => {
    setBefore(null);
    setPrevious(null);
    setNext(null);
    setPage(0);
  }, [sortDirection, sortBy, statuses, leaseMaturityDate, lastUpdated, searchInput]);

  const setRowsPerPage = (value: number) => {
    setLimit(value);
    setBefore(null);
    setPrevious(null);
    setNext(null);
    setPage(0);
  };

  const goToNextPage = () => {
    if (totalItems && limit * (page + 1) < totalItems) {
      setPreviousPage(page);
      setPage(page + 1);
      setBefore(next);
      setCursor(
        sortDirection === SortDirection.DESCENDING
          ? { before: previous ?? undefined }
          : { after: next ?? undefined },
      );
    }
  };

  const goToPreviousPage = () => {
    if (page > 0) {
      setPreviousPage(page);
      setPage(page - 1);
      setBefore(previous);
      setCursor(
        sortDirection === SortDirection.DESCENDING
          ? { after: previous ?? undefined }
          : { before: next ?? undefined },
      );
    }
  };

  const status = useMemo(() => (statuses ? statuses[0] : undefined), [statuses]);

  const handleStatusChange = useDebouncedCallback(
    async (inspectionId: string, value: TeslaInspectionStatus) => {
      try {
        await updateAdditionalData({
          id: inspectionId,
          callback: (existingData) => ({ ...existingData, customer_status: value }),
        });
        setTriggerEffect((prev) => !prev); // Toggle the state to trigger useAsyncEffect
      } catch (err) {
        handleError(err);
      }
    },
    1500,
  );

  // Effect for fetching the items
  useAsyncEffect(
    () => {
      itemsLoading.start();
      const options: GetAllInspectionsOptions = {
        filters: {
          limit,
          ownership_filter: ownershipFilter,
          ...(status ? { customer_status: status } : {}),
          ...(searchInput ? { vin: searchInput } : {}),
          ...(leaseMaturityDate
            ? {
                lease_end_date_start: leaseMaturityDate.min.toISOString(),
                lease_end_date_end: addOneDay(leaseMaturityDate.max).toISOString(),
              }
            : {}),
          ...(lastUpdated
            ? {
                last_update_date_start: lastUpdated.min.toISOString(),
                last_update_date_end: addOneDay(lastUpdated.max).toISOString(),
              }
            : {}),
        },
        sort: {
          sortByProperty: MAPPING_SORTABLE_FIELD[sortBy],
          sortOrder: MAPPING_SORT_DIRECTION[sortDirection] as SortOrder,
        },
        ...(cursor ? { pagination: cursor } : {}),
      };
      return getAllInspections(options);
    },
    [
      sortDirection,
      sortBy,
      status,
      leaseMaturityDate,
      lastUpdated,
      searchInput,
      limit,
      before,
      previousPage,
      getAllInspections,
      triggerEffect,
    ],
    {
      onResolve: (response) => {
        itemsLoading.onSuccess();
        const { entities } = response;
        const previousId = response.pagination.before ?? null;
        const nextId = response.pagination.after ?? null;
        setInspectionIds(entities.inspections.map(({ id }) => id));
        setNext(nextId);
        setPrevious(previousId);
        setCursor(null);
      },
      onReject: (err) => {
        itemsLoading.onError(err);
        handleError(err);
        setPage(previousPage);
      },
    },
  );

  // Effect for fetching the total items count
  useAsyncEffect(
    () => {
      totalLoading.start();
      const options: GetAllInspectionsOptions = {
        filters: {
          ownership_filter: ownershipFilter,
          ...(status ? { customer_status: status } : {}),
          ...(searchInput ? { vin: searchInput } : {}),
          ...(leaseMaturityDate
            ? {
                lease_end_date_start: leaseMaturityDate.min.toISOString(),
                lease_end_date_end: addOneDay(leaseMaturityDate.max).toISOString(),
              }
            : {}),
          ...(lastUpdated
            ? {
                last_update_date_start: lastUpdated.min.toISOString(),
                last_update_date_end: addOneDay(lastUpdated.max).toISOString(),
              }
            : {}),
        },
      };
      return getAllInspectionsCount(options);
    },
    [status, leaseMaturityDate, lastUpdated, searchInput, getAllInspectionsCount, triggerEffect],
    {
      onResolve: ({ count }) => {
        totalLoading.onSuccess();
        setTotalItems(count);
      },
      onReject: (err) => {
        totalLoading.onError(err);
        handleError(err);
      },
    },
  );

  return useObjectMemo({
    itemsLoading,
    totalLoading,
    inspections,
    pagination,
    setRowsPerPage,
    goToNextPage,
    goToPreviousPage,
    handleStatusChange,
  });
}
