import { Button, NumberInput, Overlay, Select, TextInput, Tooltip } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import { useForm } from '@mantine/form';
import { DatePickerInput } from '@mantine/dates';
import { clsx } from 'clsx';
import { IconCheck, IconCopy, IconLinkPlus, IconRefresh, IconCalendar } from '@tabler/icons-react';
import { useDisclosure } from '@mantine/hooks';
import { useMonitoring } from '@monkvision/monitoring';
import { MileageUnit } from '@monkvision/types';
import styles from './InspectionLinkSingleCreate.module.css';
import { useInspectionLinkSingleCreate } from '../../hooks';
import { CreateTeslaInspectionParams } from '../../hooks/useCreateTeslaInspectionLink/types';
import { TeslaCountry, TeslaModel } from '../../hooks/useTeslaInspectionList/types';
import { AppRegion, useAppRegion } from '../../contexts';

function isEmpty(value: Date | number | string | null | undefined): boolean {
  return value === '' || value === null || value === undefined;
}

function getCountryLabelKey(value: TeslaCountry): string {
  switch (value) {
    case TeslaCountry.UK:
      return 'inspectionLinkCreate.single.country.values.UK';
    case TeslaCountry.NL:
      return 'inspectionLinkCreate.single.country.values.NL';
    case TeslaCountry.FR:
      return 'inspectionLinkCreate.single.country.values.FR';
    case TeslaCountry.US:
      return 'inspectionLinkCreate.single.country.values.US';
    case TeslaCountry.DE:
      return 'inspectionLinkCreate.single.country.values.DE';
    default:
      return 'unknown-country';
  }
}

export function InspectionLinkSingleCreate() {
  const { t, i18n } = useTranslation();
  const { region } = useAppRegion();
  const required = (value?: Date | number | string) =>
    isEmpty(value) ? t('inspectionLinkCreate.single.errors.required') : null;
  const form = useForm<Partial<CreateTeslaInspectionParams>>({
    initialValues: {
      vin: undefined,
      country: region === AppRegion.US ? TeslaCountry.US : undefined,
      licencePlate: undefined,
      leaseMaturityDate: undefined,
      model: undefined,
      odometer: undefined,
      mileageUnit: MileageUnit.MILES,
    },
    validate: {
      vin: required,
      country: required,
      licencePlate: required,
      leaseMaturityDate: region === AppRegion.US ? undefined : required,
      model: required,
      mileageUnit: required,
    },
    validateInputOnChange: true,
    validateInputOnBlur: true,
  });
  const { loading, link, generateLink, reset } = useInspectionLinkSingleCreate();
  const [isTooltipOpen, { open: openTooltip, close: closeTooltip }] = useDisclosure(false);
  const { handleError } = useMonitoring();

  const handleCopyLink = async () => {
    try {
      await navigator.clipboard.writeText(link ?? '');
      openTooltip();
      setTimeout(closeTooltip, 1000);
    } catch (err) {
      handleError(err);
    }
  };

  const handleReset = () => {
    reset();
    closeTooltip();
  };

  const handleRetry = () => {
    loading.onSuccess();
  };

  return (
    <form
      className={styles['container']}
      onSubmit={form.onSubmit((values) => generateLink(values as CreateTeslaInspectionParams))}
    >
      {link && (
        <Overlay blur={5} color='#FFF' center className={styles['overlay']}>
          <div className={styles['overlayMessage']}>
            {t('inspectionLinkCreate.single.success.message')}
          </div>
          <Tooltip
            label={
              <div className={styles['tooltip']}>
                <IconCheck size={14} />
                <span>{t('inspectionLinkCreate.single.success.tooltip')}</span>
              </div>
            }
            opened={isTooltipOpen}
            withArrow
          >
            <Button
              color='dark'
              leftIcon={<IconCopy size={18} />}
              onClick={handleCopyLink}
              radius='xl'
            >
              {t('inspectionLinkCreate.single.success.copy')}
            </Button>
          </Tooltip>
          <Button
            color='dark'
            variant='outline'
            leftIcon={<IconLinkPlus size={18} />}
            onClick={handleReset}
            className={styles['resetButton']}
          >
            {t('inspectionLinkCreate.single.success.another')}
          </Button>
        </Overlay>
      )}
      {loading.error && (
        <Overlay blur={5} color='#FFF' center className={styles['overlay']}>
          <div className={clsx(styles['overlayMessage'], styles['error'])}>
            {t('inspectionLinkCreate.single.errors.generation')}
          </div>
          <Button
            color='dark'
            variant='outline'
            leftIcon={<IconRefresh size={18} />}
            onClick={handleRetry}
          >
            {t('inspectionLinkCreate.single.errors.tryAgain')}
          </Button>
        </Overlay>
      )}
      <TextInput
        placeholder={t('inspectionLinkCreate.single.vin.placeholder')}
        label={t('inspectionLinkCreate.single.vin.label')}
        withAsterisk
        className={styles['input']}
        disabled={loading.isLoading}
        {...form.getInputProps('vin')}
      />
      {region !== AppRegion.US && (
        <Select
          placeholder={t('inspectionLinkCreate.single.country.placeholder')}
          label={t('inspectionLinkCreate.single.country.label')}
          data={Object.values(TeslaCountry)
            .filter((value) => [TeslaCountry.UK, TeslaCountry.DE].includes(value))
            .map((value) => ({
              value,
              label: t(getCountryLabelKey(value)),
            }))}
          withAsterisk
          className={styles['input']}
          disabled={loading.isLoading}
          {...form.getInputProps('country')}
        />
      )}
      <TextInput
        placeholder={t('inspectionLinkCreate.single.licencePlate.placeholder')}
        label={t('inspectionLinkCreate.single.licencePlate.label')}
        withAsterisk
        className={styles['input']}
        disabled={loading.isLoading}
        {...form.getInputProps('licencePlate')}
      />
      {region !== AppRegion.US && (
        <DatePickerInput
          locale={i18n.language}
          icon={<IconCalendar size={14} />}
          type='default'
          placeholder={t('inspectionLinkCreate.single.leaseMaturityDate.placeholder')}
          label={t('inspectionLinkCreate.single.leaseMaturityDate.label')}
          disabled={loading.isLoading}
          className={styles['input']}
          withAsterisk
          {...form.getInputProps('leaseMaturityDate')}
        />
      )}
      <Select
        placeholder={t('inspectionLinkCreate.single.model.placeholder')}
        label={t('inspectionLinkCreate.single.model.label')}
        data={Object.values(TeslaModel)}
        withAsterisk
        className={styles['input']}
        disabled={loading.isLoading}
        {...form.getInputProps('model')}
      />
      <NumberInput
        placeholder={t('inspectionLinkCreate.single.odometer.placeholder')}
        label={t('inspectionLinkCreate.single.odometer.label')}
        min={0}
        rightSectionWidth={'90px'}
        rightSection={
          <Select
            data={Object.values(MileageUnit)}
            className={styles['mileageUnit']}
            disabled={loading.isLoading}
            {...form.getInputProps('mileageUnit')}
          />
        }
        className={clsx(styles['input'], styles['odometerInput'])}
        disabled={loading.isLoading}
        {...form.getInputProps('odometer')}
      />
      <div className={styles['submitContainer']}>
        <Button
          type='submit'
          color='dark'
          leftIcon={<IconLinkPlus size={18} />}
          loading={loading.isLoading}
          disabled={!form.isValid()}
        >
          {t('inspectionLinkCreate.single.submit')}
        </Button>
      </div>
    </form>
  );
}
