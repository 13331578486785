import { useTranslation } from 'react-i18next';
import { ActionIcon, Button, clsx, ScrollArea, Select } from '@mantine/core';
import { IconExternalLink, IconPencil, IconSelector } from '@tabler/icons-react';
import { PropsWithChildren, useEffect } from 'react';
import dayjs from 'dayjs';
import { useObjectTranslation, vehiclePartLabels } from '@monkvision/common';
import { MileageUnit } from '@monkvision/types';
import { DebouncedState } from 'use-debounce';
import styles from './InspectionDetails.module.css';
import { STATUS_TRANSLATION_KEYS } from '../DashboardDrawer';
import { formatPrice, getInspectionDisplayLocales } from '../../utils';
import { TeslaInspection, TeslaInspectionStatus } from '../../hooks/useTeslaInspectionList/types';
import { AppRegion, useAppRegion } from '../../contexts';

export interface InspectionDetailsProps {
  inspection: TeslaInspection;
  onOpenReview: (inspection: TeslaInspection) => void;
  onStatusChange: DebouncedState<(inspectionId: string, value: TeslaInspectionStatus) => void>;
}

function formatOdometer(value?: number, mileageUnit?: MileageUnit): string | null {
  return value && mileageUnit ? `${value} ${mileageUnit}` : null;
}

function formatSmallDate(value?: Date): string | null {
  return value ? dayjs(value).format('YYYY-MM-DD') : null;
}

function formatFullDate(value?: Date): string | null {
  return value ? dayjs(value).format('YYYY-MM-DD HH:mm:ss') : null;
}

function NullableField({ children }: PropsWithChildren<unknown>) {
  return children ? <span>{children}</span> : <span className={styles['emptyValue']}>-</span>;
}

export function isInspectionReviewAvailable(inspection: TeslaInspection): boolean {
  return (
    [
      TeslaInspectionStatus.READY_FOR_REVIEW,
      TeslaInspectionStatus.PDF_SENT,
      TeslaInspectionStatus.FINALIZED,
    ] as (TeslaInspectionStatus | undefined)[]
  ).includes(inspection.status);
}

export function InspectionDetails({
  inspection,
  onOpenReview,
  onStatusChange,
}: InspectionDetailsProps) {
  const { t, i18n } = useTranslation();
  const { tObj } = useObjectTranslation();
  const { currency } = getInspectionDisplayLocales(inspection);
  const { region } = useAppRegion();
  const formatTotalDeductions = (value?: number) => {
    return value ? formatPrice(value, currency, i18n.language) : null;
  };

  const downloadPdf = (url?: string) => {
    if (url) {
      window.open(url, '_blank')?.focus();
    }
  };

  useEffect(
    () => () => {
      onStatusChange.flush();
    },
    [onStatusChange],
  );

  return (
    <div className={styles['container']}>
      <div className={styles['vinLabel']}>{t('inspectionList.table.vin')}</div>
      <div className={styles['vinContainer']}>
        <div className={styles['vin']}>{inspection.vin}</div>
        {isInspectionReviewAvailable(inspection) && (
          <ActionIcon variant='subtle' color='dark' onClick={() => onOpenReview(inspection)}>
            <IconPencil size={20} />
          </ActionIcon>
        )}
      </div>
      <div className={styles['divider']}></div>
      <div className={styles['row']}>
        <div className={styles['col']}>{t('inspectionList.table.status')}</div>
        <div className={clsx(styles['col'], styles['dropdownStatus'])}>
          <Select
            rightSectionWidth={25}
            rightSection={<IconSelector size='20px' />}
            styles={{ rightSection: { pointerEvents: 'none' } }}
            data={Object.entries(STATUS_TRANSLATION_KEYS)
              .filter(([, value]) => value !== STATUS_TRANSLATION_KEYS['all'])
              .map(([key, value]) => ({ value: key, label: t(value) }))}
            defaultValue={inspection.status}
            onChange={(status) =>
              onStatusChange(inspection.inspectionId, status as TeslaInspectionStatus)
            }
          />
        </div>
      </div>
      <div className={styles['row']}>
        <div className={styles['col']}>{t('inspectionList.table.id')}</div>
        <div className={styles['col']}>
          <NullableField>{inspection.inspectionId}</NullableField>
        </div>
      </div>
      <div className={styles['row']}>
        <div className={styles['col']}>{t('inspectionList.table.model')}</div>
        <div className={styles['col']}>
          <NullableField>{inspection.model}</NullableField>
        </div>
      </div>
      <div className={styles['row']}>
        <div className={styles['col']}>{t('inspectionList.table.licencePlate')}</div>
        <div className={styles['col']}>
          <NullableField>{inspection.licencePlate}</NullableField>
        </div>
      </div>
      <div className={styles['row']}>
        <div className={styles['col']}>{t('inspectionList.table.odometer')}</div>
        <div className={styles['col']}>
          <NullableField>
            {formatOdometer(inspection.odometer, inspection.mileageUnit)}
          </NullableField>
        </div>
      </div>
      <div className={styles['row']}>
        <div className={styles['col']}>{t('inspectionList.table.totalDeductions')}</div>
        <div className={styles['col']}>
          <NullableField>{formatTotalDeductions(inspection.totalPricing)}</NullableField>
        </div>
      </div>
      <div className={styles['row']}>
        <div className={styles['col']}>{t('inspectionList.table.deductionDetails')}</div>
        <div className={styles['col']}>
          {inspection.deductionDetails ? (
            <ScrollArea.Autosize mah={100} type='auto'>
              {inspection.deductionDetails.map(({ part, pricing }) => (
                <div key={part} className={styles['deductionBadge']}>
                  {`${
                    vehiclePartLabels[part] ? tObj(vehiclePartLabels[part]) : 'unknown'
                  } ${formatPrice(pricing, currency, i18n.language)}`}
                </div>
              ))}
            </ScrollArea.Autosize>
          ) : (
            <span className={styles['emptyValue']}>-</span>
          )}
        </div>
      </div>
      <div className={styles['row']}>
        <div className={styles['col']}>{t('inspectionList.table.otherDeductions')}</div>
        <div className={styles['col']}>
          {inspection.interiorDamages ? (
            <ScrollArea.Autosize mah={100} type='auto'>
              {inspection.interiorDamages.map(({ area, pricing }, index) => (
                <div key={index} className={styles['deductionBadge']}>
                  {`${area} ${formatPrice(pricing, currency, i18n.language)}`}
                </div>
              ))}
            </ScrollArea.Autosize>
          ) : (
            <span className={styles['emptyValue']}>-</span>
          )}
        </div>
      </div>
      <div className={styles['row']}>
        <div className={styles['col']}>{t('inspectionList.table.pdf')}</div>
        <div className={styles['col']}>
          {inspection.pdfUrl ? (
            <Button
              variant='white'
              size='xs'
              rightIcon={<IconExternalLink size={14} />}
              onClick={() => downloadPdf(inspection.pdfUrl)}
            >
              {t('inspectionList.table.pdf')}
            </Button>
          ) : (
            <span className={styles['emptyValue']}>-</span>
          )}
        </div>
      </div>
      <div className={styles['row']}>
        <div className={styles['col']}>{t('inspectionList.table.lastUpdated')}</div>
        <div className={styles['col']}>
          <NullableField>{formatFullDate(inspection.lastUpdated)}</NullableField>
        </div>
      </div>
      {region !== AppRegion.US && (
        <div className={styles['row']}>
          <div className={styles['col']}>{t('inspectionList.table.leaseMaturityDate')}</div>
          <div className={styles['col']}>
            <NullableField>{formatSmallDate(inspection.leaseMaturityDate)}</NullableField>
          </div>
        </div>
      )}
      <div className={styles['row']}>
        <div className={styles['col']}>{t('inspectionList.table.inspectionStart')}</div>
        <div className={styles['col']}>
          <NullableField>{formatFullDate(inspection.inspectionStart)}</NullableField>
        </div>
      </div>
      <div className={styles['row']}>
        <div className={styles['col']}>{t('inspectionList.table.inspectionEnd')}</div>
        <div className={styles['col']}>
          <NullableField>{formatFullDate(inspection.inspectionEnd)}</NullableField>
        </div>
      </div>
    </div>
  );
}
