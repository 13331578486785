import { useCallback, useState } from 'react';
import { AppRegion, useAppRegion } from '../../contexts';

function getExampleCsv(region: AppRegion | null) {
  const defaultExampleCsv =
    'VIN,Country,Licence Plate,Lease Maturity Date,Model,Odometer,Mileage Unit\n' +
    '5YJXCBE20GF005643,UK,NU74REG,2024-12-25,Model 3,23000,km\n' +
    '1YJSA2H17EFP25201,UK,KY24TKT,2024-11-05,Model S,12345,miles\n' +
    '584SA1DN0DFP11416,DE,HJ56RYA,2025-04-30,Model X,93721,km\n' +
    '943FE4DN0DFP11416,DE,PAO523K,2025-04-20,Model Y,,\n' +
    '1239837G0DFP11416,UK,FJ32F45,2024-11-21,Model 3,,';

  const usExampleCsv =
    'VIN,Country,Licence Plate,Model,Odometer,Mileage Unit\n' +
    '5YJXCBE20GF005643,US,NU74REG,Model 3,23000,km\n' +
    '1YJSA2H17EFP25201,US,KY24TKT,Model S,12345,miles\n' +
    '584SA1DN0DFP11416,US,HJ56RYA,Model X,93721,km\n' +
    '943FE4DN0DFP11416,US,PAO523K,Model Y,,\n' +
    '1239837G0DFP11416,US,FJ32F45,Model 3,,';

  return region === AppRegion.US ? usExampleCsv : defaultExampleCsv;
}

export function useExampleCsv() {
  const [anchor, setAnchor] = useState<HTMLAnchorElement | null>(null);
  const { region } = useAppRegion();

  const downloadExampleCsv = useCallback(() => {
    if (!anchor) {
      const blob = new Blob([getExampleCsv(region)], { type: 'text/csv' });
      const url = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = url;
      a.download = 'example.csv';
      a.click();
      setAnchor(anchor);
    } else {
      anchor.click();
    }
  }, [anchor]);

  return { downloadExampleCsv };
}
